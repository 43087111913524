/* LOCAL STORAGE */
if(!isDevPresentation() && window.location.protocol !== "http:" && window.location.pathname.search("index_custom.php") === -1) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('ServiceWorker.js').then(function (reg) {
            //reg.installing; // the installing worker, or undefined
            //reg.waiting; // the waiting worker, or undefined
            //reg.active; // the active worker, or undefined

            reg.addEventListener('updatefound', function() {
                var newWorker = reg.installing;

                Messagebox.showMessagebox("screenbook wird aktualisiert...");
                console.log("workerstate: "+newWorker.state);
                // "installing" - the install event has fired, but not yet complete
                // "installed"  - install complete
                // "activating" - the activate event has fired, but not yet complete
                // "activated"  - fully active
                // "redundant"  - discarded. Either failed install, or it's been
                //                replaced by a newer version

                newWorker.addEventListener('statechange', function() {
                    console.log("statechange: "+newWorker.state);
                    if(newWorker.state === "activated") {
                        Messagebox.showMessagebox('Neue Version verfügbar: <a href="#" onclick="window.location.reload(true); return false;">neu laden</a>', 0, false);
                    }
                });
            });

            console.log('Registrierung erfolgreich. Scope ist ' + reg.scope);
        }).catch(function (error) {
            console.log('Registrierung fehlgeschlagen mit ' + error);
        });
    }

    //manually add mp4 videos to cache (otherwise bug in ios)
    if('caches' in window) {
        caches.open('videos').then(function(cache) {
            cache.addAll("###MP4FILES###");
        });
    }
}


/* INIT REVEAL */
var revealOptions = {
    viewDistance: 2,
    maxScale: 1,
    width: 1024,
    height: 768,
    margin: 0.15,
    help: false,
    hash: true,
    center: true,
    transition: 'convex', // none/fade/slide/convex/concave/zoom
    dependencies: [
        { src: 'plugin/chart/Chart.js' },
        { src: 'plugin/chart/csv2chart.js' },
        { src: 'plugin/threed/threed.js', async: true },
        { src: 'plugin/gallery/gallery.js' },
        { src: 'plugin/custompresentation/custompresentation.js', async: true },
        { src: 'plugin/timeline/timeline.js' },
        { src: 'plugin/videoplayer/videoplayer.js'},
        { src: 'plugin/features/features.js' }
    ]
};

if(!isCustomPresentation()){
    revealOptions.draw = {
        colors: ["#d79e1d", "#666666", "#000000"]
    };
    revealOptions.vcards = [
        {name: "Thomas Hausner", vcard: "vcards/thomas_hausner.vcf"},
        {name: "Christian Katzinger", vcard: "vcards/christian_katzinger.vcf"},
        {name: "David Brunnthaler", vcard: "vcards/david_brunnthaler.vcf"},
        {name: "Marcin Szostak", vcard: "vcards/marcin_szostak.vcf"}
    ];
    revealOptions.toolbox = {
        //"hideOnSlides": ["start"]
    };
    revealOptions.mainnavi = {
        "hideOnSlides": ["start"]
    };
    revealOptions.logo = {
        "hideOnSlides": ["start"]
    };
    revealOptions.dependencies = [
        { src: 'plugin/mainnavi/mainnavi.js', async: true },
        { src: 'plugin/toolbox/toolbox.js', async: true },
        { src: 'plugin/overlay/overlay.js' },
        { src: 'plugin/chart/Chart.js' },
        { src: 'plugin/chart/csv2chart.js' },
        { src: 'plugin/draw/signature_pad.js' },
        { src: 'plugin/draw/draw.js', async: true },
        { src: 'plugin/container/container.js' },
        { src: 'plugin/messagebox/messagebox.js' },
        { src: 'plugin/spinner/spinner.js' },
        { src: 'plugin/threed/threed.js', async: true },
        { src: 'plugin/vcards/vcards.js' },
        { src: 'plugin/search/search.js' },
        { src: 'plugin/timeline/timeline.js' },
        { src: 'plugin/gallery/gallery.js' },
        { src: 'plugin/pages/pages.js' , async: true},
        { src: 'plugin/videoplayer/videoplayer.js' },
        { src: 'plugin/features/features.js' },
        { src: 'plugin/tutorial/tutorial.js', async: true }
    ];
}
Reveal.initialize(revealOptions);



if(document.getElementById("logo") !== null) {
    /*SHOW / HIDE LOGO ON SLIDES*/
    function hideLogoOnPages(slideId){
        if(typeof(slideId) === "undefined"){
            slideId = Reveal.getCurrentSlide().getAttribute("id");
        }

        var config = Reveal.getConfig().logo || {};
        var hideOnSlides = config.hideOnSlides || [];

        if(inArray(slideId, hideOnSlides)){
            document.getElementById("logo").style.display = "none";
        }else {
            document.getElementById("logo").style.display = "block";
        }
    }
    Reveal.addEventListener( 'ready', function( event ) {
        hideLogoOnPages();
    });
    Reveal.addEventListener( 'slidechanged', function( event ) {
        hideLogoOnPages();
    });


    /*SCALE LOGO*/
    function scaleLogo() {
        var scale = Reveal.getScale();
        if (scale > 1) scale = 1;
        else if (scale < 0.4) scale = 0.4;
        document.getElementById("logo").style.transform = "scale(" + scale + ")";
    }

    scaleLogo();
    Reveal.addEventListener('resize', function () {
        scaleLogo();
    });
}
