//Bathroom Navi
if(document.getElementById("bathroomimage") !== null) {
    document.getElementById("bathroomimage").addEventListener("click", function (e) {
        e.preventDefault();

        var navilinks = document.getElementById("bathroom_navi").querySelectorAll(".navilink");
        for (var i = 0; i < navilinks.length; i++) {
            if (navilinks[i].classList.contains("active")) {
                navilinks[i].classList.remove("active");
            } else {
                navilinks[i].classList.add("active");
            }
        }

        return false;
    });
}
