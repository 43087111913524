function isDevPresentation(){
    if(window.location.href.search("dev") !== -1 || window.location.href.search("localhost") !== -1) return true;
    else return false;
}

function getPageTitleFromSlideId(slideId) {
    var slide = document.getElementById(slideId);
    var pageTitle = slide.getAttribute("data-pagetitle");

    if(pageTitle === null || typeof(pageTitle) === "undefined" || pageTitle === ""){
        return slideId;
    }else {
        return stripTags(pageTitle);
    }
}

function stripTags(text){
    return text.replace(/(<([^>]+)>)/ig,"");
}

function getAjax(url, success, error) {
    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('GET', url);

    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 400) {
            var response = JSON.parse(xhr.responseText);
            success(response);
        } else {
            var response = "Fehler "+xhr.responseText;
            error(response);
        }
    };
    xhr.onerror = function() {
        var response = "Fehler "+xhr.responseText;
        error(response);
    };

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader("Authorization", "Basic c2NyZWVuYm9va0FqYXhBcGk6TWM0MjNPdDZVczE3");
    xhr.send();
    return xhr;
}

function postAjax(url, data, success, error) {
    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
    xhr.open('POST', url);

    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 400) {
            var response = JSON.parse(xhr.responseText);
            success(response);
        } else {
            var response = "Fehler "+xhr.responseText;
            error(response);
        }
    };
    xhr.onerror = function() {
        var response = "Fehler "+xhr.responseText;
        error(response);
    };

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.setRequestHeader("Authorization", "Basic c2NyZWVuYm9va0FqYXhBcGk6TWM0MjNPdDZVczE3");
    xhr.send('data='+encodeURIComponent(JSON.stringify(data)));
    return xhr;
}

function isCustomPresentation(){
    if (document.querySelector("html").classList.contains('custom')) {
        return true;
    }else {
        return false;
    }
}

function inArray(needle, haystack) {
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
        if(haystack[i] === needle) return true;
    }
    return false;
}

function getBodyHeight(){
    var body = document.body;
    var html = document.documentElement;
    return Math.max(body.scrollHeight, body.offsetHeight, body.getBoundingClientRect().height, html.clientHeight, html.scrollHeight, html.offsetHeight);
}
function offset(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function number_format(number,decimals,dec_point,thousands_sep) {
    number  = number*1;//makes sure `number` is numeric value
    var str = number.toFixed(decimals?decimals:0).toString().split('.');
    var parts = [];
    for ( var i=str[0].length; i>0; i-=3 ) {
        parts.unshift(str[0].substring(Math.max(0,i-3),i));
    }
    str[0] = parts.join(thousands_sep?thousands_sep:',');
    return str.join(dec_point?dec_point:'.');
}

function isMobileDevice(){
    return /(iphone|ipod|ipad|android)/gi.test(navigator.userAgent);
}


// .closest polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
